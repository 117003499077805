import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Logo from "../../../packages/Logo/src";
import Container from "../../../packages/Container/src";
import Flex from "../../../packages/Flex/src";
import Div from "../../../packages/Div/src";
import Icon from "../../../packages/Icon/src";
var StyledHeader = styled("header").withConfig({
  displayName: "SiteHeader__StyledHeader",
  componentId: "jwdsuh-0"
})(["position:relative;box-shadow:0 0.25rem 1rem rgba(0,0,0,0.1);z-index:9999;background-color:#fff;"]);

var SiteHeader = function SiteHeader() {
  return React.createElement(StyledHeader, null, React.createElement(Container, null, React.createElement(Flex, {
    alignItems: "center",
    justifyContent: "space-between",
    py: "0.75rem"
  }, React.createElement(Div, null, React.createElement(Link, {
    to: "/"
  }, React.createElement(Logo, {
    text: "Ds-kit"
  }))), React.createElement(Flex, null, React.createElement(Div, {
    color: "text",
    mr: "2rem"
  }, React.createElement(Link, {
    to: "/typography"
  }, "Design")), React.createElement(Div, {
    color: "text",
    mr: "2rem"
  }, React.createElement(Link, {
    to: "/packages/avatar"
  }, "Components")), React.createElement(Div, {
    color: "text",
    mr: "2rem"
  }, React.createElement(Link, {
    to: "/patterns"
  }, "Patterns")), React.createElement(Div, {
    color: "text",
    mr: "2rem"
  }, React.createElement(Link, {
    to: "/tools"
  }, "Tools")), React.createElement(Div, {
    color: "text"
  }, React.createElement("a", {
    href: "https://github.com/datastory-org/ds-kit",
    target: "_blank",
    rel: "noopener noreferrer"
  }, React.createElement(Icon, {
    name: "github"
  })))))));
};

export default SiteHeader;