import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { buttonStyle, space, system, color, borderRadius, boxShadow } from "styled-system";
import { Button as RKButton } from "reakit/Button";
import { filterProps } from "../../../src/utils/filterProps";
var allPropNames = [].concat(buttonStyle.propNames, space.propNames, color.propNames, borderRadius.propNames, boxShadow.propNames, ["width", "height", "bgHover", "colorHover", "focusOutline", "size", "is", "raised", "boxShadowHover", "buttonPadding", "hoverBg"]);
var buttonWidth = system({
  width: {
    property: "width",
    scale: "buttonSizes",
    defaultScale: {
      sm: "2rem",
      md: "2.5rem",
      lg: "3rem"
    }
  }
});
var buttonHeight = system({
  height: {
    property: "height",
    scale: "buttonSizes",
    defaultScale: {
      sm: "2rem",
      md: "2.5rem",
      lg: "3rem"
    }
  }
});
var buttonPadding = system({
  buttonPadding: {
    properties: ["padding-left", "padding-right"],
    scale: "buttonPaddings",
    defaultScale: {
      sm: "1rem",
      md: "1.5rem",
      lg: "2rem"
    }
  }
});
var colorHover = system({
  colorHover: {
    property: "color",
    scale: "colors"
  },
  bgHover: {
    property: "backgroundColor",
    scale: "colors"
  }
});
var boxShadowHover = system({
  boxShadowHover: {
    property: "box-shadow",
    scale: "shadows"
  }
});

var configureAttributes = function configureAttributes(props) {
  return {
    height: props.height || props.size,
    buttonPadding: props.size,
    boxShadow: props.raised ? "sm" : null,
    boxShadowHover: props.raised ? "md" : null
  };
};

var StyledButton = styled(React.forwardRef(function (_ref, ref) {
  var is = _ref.is,
      props = _objectWithoutPropertiesLoose(_ref, ["is"]);

  var filteredProps = filterProps(props, allPropNames);
  return React.createElement(RKButton, Object.assign({
    ref: ref,
    as: is
  }, filteredProps));
})).attrs(configureAttributes).withConfig({
  displayName: "src__StyledButton",
  componentId: "sc-1amumin-0"
})(["appearance:none;display:inline-flex;vertical-align:middle;align-items:center;justify-content:center;font-family:inherit;font-size:inherit;line-height:1.25;font-weight:600;border:0;outline:none;user-select:none;transition:box-shadow 100ms,background-color 100ms,color 100ms;cursor:pointer;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;text-decoration:none;padding-top:0;padding-bottom:0;color:inherit;background:transparent;-webkit-tap-highlight-color:rgba(0,0,0,0);&::-moz-focus-inner{border:0;}", " ", " ", " ", " ", " ", " ", " ", " &:hover,&:focus{", " ", "}&:focus{", "}&:active{", "}"], buttonStyle, buttonWidth, buttonHeight, buttonPadding, color, space, borderRadius, boxShadow, colorHover, boxShadowHover, boxShadowHover, boxShadow);
var Button = React.forwardRef(function (_ref2, ref) {
  var as = _ref2.as,
      restProps = _objectWithoutPropertiesLoose(_ref2, ["as"]);

  return React.createElement(StyledButton, Object.assign({
    ref: ref,
    is: as
  }, restProps));
});
Button.defaultProps = {
  borderRadius: "default",
  variant: "default",
  size: "md"
};
Button.propTypes = {
  as: PropTypes.any,
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
};
export default Button;