import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

/* eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }] */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { display, width, space } from "styled-system";
import Button from "@ds-kit/button";
var defaultItems = [{
  key: 1,
  label: "Item 1"
}, {
  key: 2,
  label: "Item 2"
}, {
  key: 3,
  label: "Item 3"
}];
export var ButtonGroupBase = function ButtonGroupBase(_ref) {
  var children = _ref.children,
      selected = _ref.selected,
      initial = _ref.initial,
      _ref$items = _ref.items,
      items = _ref$items === void 0 ? defaultItems : _ref$items,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? "md" : _ref$size,
      onChange = _ref.onChange,
      _ref$buttonColor = _ref.buttonColor,
      buttonColor = _ref$buttonColor === void 0 ? "text" : _ref$buttonColor,
      _ref$activeButtonColo = _ref.activeButtonColor,
      activeButtonColor = _ref$activeButtonColo === void 0 ? "white" : _ref$activeButtonColo,
      _ref$buttonBg = _ref.buttonBg,
      buttonBg = _ref$buttonBg === void 0 ? "grey.50" : _ref$buttonBg,
      _ref$buttonHoverBg = _ref.buttonHoverBg,
      buttonHoverBg = _ref$buttonHoverBg === void 0 ? "grey.100" : _ref$buttonHoverBg,
      _ref$activeButtonBg = _ref.activeButtonBg,
      activeButtonBg = _ref$activeButtonBg === void 0 ? "primary" : _ref$activeButtonBg,
      _ref$activeButtonHove = _ref.activeButtonHoverBg,
      activeButtonHoverBg = _ref$activeButtonHove === void 0 ? "primary" : _ref$activeButtonHove,
      _ref$buttonProps = _ref.buttonProps,
      buttonProps = _ref$buttonProps === void 0 ? {} : _ref$buttonProps,
      restProps = _objectWithoutPropertiesLoose(_ref, ["children", "selected", "initial", "items", "size", "onChange", "buttonColor", "activeButtonColor", "buttonBg", "buttonHoverBg", "activeButtonBg", "activeButtonHoverBg", "buttonProps"]);

  var _useState = useState(selected || initial || items[0]),
      sel = _useState[0],
      setSelected = _useState[1];

  var handleChange = function handleChange(item) {
    return function () {
      if (!selected) {
        setSelected(item);
      } else if (onChange) onChange(item);
    };
  };

  useEffect(function () {
    if (onChange) onChange(sel);
  }, [sel]);
  useEffect(function () {
    if (selected) setSelected(selected);
  }, [selected]);

  var getItemProps = function getItemProps(item) {
    return _objectSpread({}, item, {
      onClick: handleChange(item)
    });
  };

  var childrenIsFunction = typeof children === "function";
  return React.createElement("div", restProps, childrenIsFunction ? children(sel, getItemProps) : items.map(function (item) {
    return React.createElement(Button, Object.assign({
      key: item.key,
      size: size,
      onClick: handleChange(item),
      color: item.key === sel.key ? activeButtonColor : buttonColor,
      bg: item.key === sel.key ? activeButtonBg : buttonBg,
      bgHover: item.key === sel.key ? activeButtonHoverBg : buttonHoverBg,
      colorHover: item.key === sel.key ? activeButtonColor : buttonColor
    }, buttonProps), item.label);
  }));
};
var ButtonGroup = styled(ButtonGroupBase).withConfig({
  displayName: "src__ButtonGroup",
  componentId: "loghid-0"
})(["", " vertical-align:middle;align-items:center;", " ", " max-width:100%;> *:not(:first-child):not(:last-child){border-radius:0;margin-right:0.0625rem;}> *:first-child{border-top-right-radius:0;border-bottom-right-radius:0;margin-right:0.0625rem;}> *:last-child{border-top-left-radius:0;border-bottom-left-radius:0;}"], display, width, space);
ButtonGroup.defaultProps = {
  display: "inline-flex"
};
export default ButtonGroup;