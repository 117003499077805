import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import styled from "styled-components";
import { color, space } from "styled-system";
var StyledRatioContainer = styled.div.withConfig({
  displayName: "src__StyledRatioContainer",
  componentId: "sc-1p110rs-0"
})(["position:relative;height:0;", ""], space);
var StyledRatioContent = styled.div.withConfig({
  displayName: "src__StyledRatioContent",
  componentId: "sc-1p110rs-1"
})(["position:absolute;top:0;left:0;bottom:0;right:0;", ";"], color);
/**
 * RatioContainer can be used to wrap content in a fixed ratio div.
 *
 */

var RatioContainer = function RatioContainer(_ref) {
  var _ref$ratio = _ref.ratio,
      ratio = _ref$ratio === void 0 ? 1 / 2 : _ref$ratio,
      _ref$bg = _ref.bg,
      bg = _ref$bg === void 0 ? "primary" : _ref$bg,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? "white" : _ref$color,
      restProps = _objectWithoutPropertiesLoose(_ref, ["ratio", "bg", "color"]);

  var pb = ratio.map ? ratio.map(function (d) {
    return d ? 100 * d + "%" : null;
  }) : 100 * ratio + "%";
  return React.createElement(StyledRatioContainer, {
    pb: pb
  }, React.createElement(StyledRatioContent, Object.assign({
    bg: bg,
    color: color
  }, restProps)));
};

export default RatioContainer;