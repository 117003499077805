import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

/* eslint-disable import/no-namespace */

/* eslint-disable import/max-dependencies */

/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import SiteHeader from "../SiteHeader";
import GlobalStyle from "../GlobalStyle";
import * as theme from "../../../packages/theme/src";
import RatioContainer from "../../../packages/RatioContainer/src";
import Container from "../../../packages/Container/src";
import Div from "../../../packages/Div/src";
import { TabBar } from "../../../packages/Tabs/src";
import ButtonGroup from "../../../packages/ButtonGroup/src";
import Button from "../../../packages/Button/src";
import Link from "../../../packages/Link/src";
import Flex from "../../../packages/Flex/src";

var Switcher = function Switcher(_ref) {
  var onChange = _ref.onChange,
      currentView = _ref.currentView;
  return React.createElement(TabBar, {
    selected: currentView,
    items: [{
      key: 0,
      label: "Result"
    }, {
      key: 1,
      label: "Code"
    }],
    onChange: onChange,
    borderColor: "white",
    height: "3.5rem"
  });
};

var viewports = {
  Mobile: {
    label: "Mobile",
    key: 1,
    w: 375,
    ratio: 1.78 / 1
  },
  Tablet: {
    label: "Tablet",
    key: 2,
    w: 768,
    ratio: 4 / 3
  },
  Desktop: {
    label: "Desktop",
    key: 3,
    w: 1440,
    ratio: 9 / 16
  }
};

var PatternWrapperLayout = function PatternWrapperLayout(_ref2) {
  var children = _ref2.children,
      restProps = _objectWithoutPropertiesLoose(_ref2, ["children"]);

  var ctx = restProps.pageContext;

  var _useState = useState(0),
      view = _useState[0],
      setView = _useState[1];

  var _useState2 = useState("Mobile"),
      viewport = _useState2[0],
      setViewport = _useState2[1];

  var handleSwitch = function handleSwitch(selectedView) {
    setView(selectedView.key);
  };

  var handleViewportChange = function handleViewportChange(selectedViewport) {
    setViewport(viewports[selectedViewport.label].label);
  };

  return React.createElement(ThemeProvider, {
    theme: theme
  }, React.createElement(React.Fragment, null, React.createElement(GlobalStyle, null), React.createElement(SiteHeader, null), React.createElement(Div, {
    bg: "grey.50"
  }, React.createElement(Container, null, React.createElement(Flex, {
    justifyContent: "space-between",
    alignItems: "center"
  }, React.createElement(Div, null, React.createElement(Switcher, {
    onChange: handleSwitch,
    currentView: view
  })), React.createElement(Div, null, React.createElement(ButtonGroup, {
    selected: viewports[viewport],
    onChange: handleViewportChange
  }, function (selected, getItemProps) {
    return React.createElement(React.Fragment, null, Object.keys(viewports).map(function (d) {
      var item = viewports[d];
      var isSelected = item.key === selected.key;
      return React.createElement(Button, Object.assign({
        key: item.key,
        size: "sm",
        variant: isSelected ? "primary" : "default",
        style: {
          zIndex: isSelected ? 2 : 1
        }
      }, getItemProps(item)), item.label === "Mobile" && React.createElement("svg", {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20",
        height: "20",
        viewBox: "0 0 24 24",
        fill: "none",
        stroke: "currentcolor",
        strokeWidth: "2",
        strokeLinecap: "square",
        strokeLinejoin: "arcs"
      }, React.createElement("rect", {
        x: "5",
        y: "2",
        width: "14",
        height: "20",
        rx: "2",
        ry: "2"
      }), React.createElement("line", {
        x1: "12",
        y1: "18",
        x2: "12",
        y2: "18"
      })), item.label === "Tablet" && React.createElement("svg", {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20",
        height: "20",
        viewBox: "0 0 24 24",
        fill: "none",
        stroke: "currentcolor",
        strokeWidth: "2",
        strokeLinecap: "square",
        strokeLinejoin: "arcs"
      }, React.createElement("rect", {
        x: "4",
        y: "2",
        width: "16",
        height: "20",
        rx: "2",
        ry: "2",
        transform: "rotate(180 12 12)"
      }), React.createElement("line", {
        x1: "12",
        y1: "18",
        x2: "12",
        y2: "18"
      })), item.label === "Desktop" && React.createElement("svg", {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20",
        height: "20",
        viewBox: "0 0 24 24",
        fill: "none",
        stroke: "currentcolor",
        strokeWidth: "2",
        strokeLinecap: "square",
        strokeLinejoin: "arcs"
      }, React.createElement("rect", {
        x: "2",
        y: "3",
        width: "20",
        height: "14",
        rx: "2",
        ry: "2"
      }), React.createElement("line", {
        x1: "8",
        y1: "21",
        x2: "16",
        y2: "21"
      }), React.createElement("line", {
        x1: "12",
        y1: "17",
        x2: "12",
        y2: "21"
      })));
    }));
  }))))), view === 0 && React.createElement(React.Fragment, null, React.createElement(Div, {
    bg: "grey.900",
    color: "grey.50",
    pt: "2.5rem",
    pb: "5rem",
    px: "3rem"
  }, React.createElement(Div, {
    textAlign: "center",
    pb: "1.5rem"
  }, React.createElement(Link, {
    href: "/patterns/" + ctx.patternName,
    target: "_blank",
    rel: "noopener noreferrer",
    variant: "standalone"
  }, React.createElement(Flex, {
    display: "inline-flex",
    alignItems: "center"
  }, "Open in a separate window", React.createElement("svg", {
    style: {
      display: "inline-block",
      verticalAlign: "middle",
      marginLeft: "0.5rem"
    },
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentcolor",
    strokeWidth: "2",
    strokeLinecap: "square",
    strokeLinejoin: "arcs"
  }, React.createElement("path", {
    d: "M10 3H6a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h4M16 17l5-5-5-5M19.8 12H9"
  }))))), React.createElement(Div, {
    maxWidth: viewports[viewport].w,
    mx: "auto"
  }, React.createElement(RatioContainer, {
    ratio: viewports[viewport].ratio,
    style: {
      borderRadius: "0.5rem",
      overflow: "hidden"
    }
  }, React.createElement("iframe", {
    id: "inlineFrameExample",
    title: "Inline Frame Example",
    width: "100%",
    height: "100%",
    src: "/patterns/" + ctx.patternName,
    style: {
      border: "0"
    }
  }))))), view === 1 && React.createElement(Div, {
    bg: "grey.900",
    color: "grey.50",
    py: "5rem",
    px: "3rem",
    overflow: "scroll"
  }, React.createElement("pre", {
    style: {
      fontFamily: "Menlo"
    },
    dangerouslySetInnerHTML: {
      __html: restProps.pageContext.code
    }
  })), React.createElement(Div, {
    bg: "grey.50",
    py: "5rem",
    px: "3rem"
  }, "\xA0")));
};

export default PatternWrapperLayout;