import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import React from 'react';
import styled from 'styled-components';
import { buttonStyle, space, color, borderRadius, boxShadow, system } from 'styled-system';
import { Button as Button$1 } from 'reakit/Button';
import 'core-js/modules/web.dom.iterable';

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _taggedTemplateLiteralLoose(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  strings.raw = raw;
  return strings;
}

var filterProps = function filterProps(props, allPropNames) {
  return Object.keys(props).reduce(function (acc, cur) {
    if (allPropNames.indexOf(cur) === -1) acc[cur] = props[cur];
    return acc;
  }, {});
};

function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n  appearance: none;\n  display: inline-flex;\n  vertical-align: middle;\n  align-items: center;\n  justify-content: center;\n  font-family: inherit;\n  font-size: inherit;\n  line-height: 1.25;\n  font-weight: 600;\n  border: 0;\n  outline: none;\n  user-select: none;\n  transition: box-shadow 100ms, background-color 100ms, color 100ms;\n  cursor: pointer;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-decoration: none;\n\n  padding-top: 0;\n  padding-bottom: 0;\n\n  color: inherit;\n  background: transparent;\n\n  -webkit-tap-highlight-color: rgba(0,0,0,0);\n  &::-moz-focus-inner {\n    border: 0;\n  }\n\n  ", "\n\n  ", "\n  ", "\n  ", "\n\n  ", "\n  ", "\n\n  ", "\n\n  ", "\n\n  &:hover,\n  &:focus {\n    ", "\n    ", "\n  }\n  &:focus {\n    ", "\n  }\n  &:active {\n    ", "\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var allPropNames = [].concat(buttonStyle.propNames, space.propNames, color.propNames, borderRadius.propNames, boxShadow.propNames, ["width", "height", "bgHover", "colorHover", "focusOutline", "size", "is", "raised", "boxShadowHover", "buttonPadding", "hoverBg"]);
var buttonWidth = system({
  width: {
    property: "width",
    scale: "buttonSizes",
    defaultScale: {
      sm: "2rem",
      md: "2.5rem",
      lg: "3rem"
    }
  }
});
var buttonHeight = system({
  height: {
    property: "height",
    scale: "buttonSizes",
    defaultScale: {
      sm: "2rem",
      md: "2.5rem",
      lg: "3rem"
    }
  }
});
var buttonPadding = system({
  buttonPadding: {
    properties: ["padding-left", "padding-right"],
    scale: "buttonPaddings",
    defaultScale: {
      sm: "1rem",
      md: "1.5rem",
      lg: "2rem"
    }
  }
});
var colorHover = system({
  colorHover: {
    property: "color",
    scale: "colors"
  },
  bgHover: {
    property: "backgroundColor",
    scale: "colors"
  }
});
var boxShadowHover = system({
  boxShadowHover: {
    property: "box-shadow",
    scale: "shadows"
  }
});

var configureAttributes = function configureAttributes(props) {
  return {
    height: props.height || props.size,
    buttonPadding: props.size,
    boxShadow: props.raised ? "sm" : null,
    boxShadowHover: props.raised ? "md" : null
  };
};

var StyledButton = styled(React.forwardRef(function (_ref, ref) {
  var is = _ref.is,
      props = _objectWithoutProperties(_ref, ["is"]);

  var filteredProps = filterProps(props, allPropNames);
  return React.createElement(Button$1, _extends({
    ref: ref,
    as: is
  }, filteredProps));
})).attrs(configureAttributes).withConfig({
  displayName: "indexes__StyledButton",
  componentId: "sc-1h1wxng-0"
})(_templateObject(), buttonStyle, buttonWidth, buttonHeight, buttonPadding, color, space, borderRadius, boxShadow, colorHover, boxShadowHover, boxShadowHover, boxShadow);
var Button = React.forwardRef(function (_ref2, ref) {
  var as = _ref2.as,
      restProps = _objectWithoutProperties(_ref2, ["as"]);

  return React.createElement(StyledButton, _extends({
    ref: ref,
    is: as
  }, restProps));
});
Button.defaultProps = {
  borderRadius: "default",
  variant: "default",
  size: "md"
};
export default Button;