import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { display, space, width, height, borderColor, color } from "styled-system";
var Wrapper = styled.div.withConfig({
  displayName: "src__Wrapper",
  componentId: "sc-1dk4pbt-0"
})(["overflow-y:hidden;max-width:100%;border-bottom:0.0625rem solid;", ""], borderColor);
var Slider = styled.div.withConfig({
  displayName: "src__Slider",
  componentId: "sc-1dk4pbt-1"
})(["display:flex;white-space:nowrap;padding-bottom:2rem;margin-bottom:-2rem;overflow-x:auto;-webkit-overflow-scrolling:touch;scroll-behavior:", ";"], function (p) {
  return p.scrollBehavior;
});
var Tab = styled.button.withConfig({
  displayName: "src__Tab",
  componentId: "sc-1dk4pbt-2"
})(["", " align-items:center;justify-content:center;font-family:inherit;font-size:inherit;font-weight:600;line-height:1.25;border:none;background:transparent;flex:none;", " ", " ", " ", " cursor:pointer;outline:none;border-bottom:0.1875rem solid;", " &:hover,&:focus{background:rgba(0,0,0,0.05);}"], display, space, height, width, color, borderColor);
Tab.defaultProps = {
  display: "inline-flex",
  py: "0",
  px: "1.25rem"
};

function getScrollOffset(scroller, target, sideOffset) {
  var box1 = scroller.getBoundingClientRect();
  var box2 = target.getBoundingClientRect();
  var leftOffset = scroller.scrollLeft;
  var scrollOffset = leftOffset + box2.x + box2.width - box1.width - box1.x + sideOffset;

  if (box1.width < box2.x + box2.width) {
    return scrollOffset;
  } else if (box2.x - box1.x < 0) {
    return leftOffset + box2.x - box1.x - sideOffset;
  } else {
    return null;
  }
}

function setInitialScroll(scroller, current, sideOffset) {
  var childNodes = scroller.childNodes;
  if (!childNodes.length) return;
  var scrollOffset = getScrollOffset(scroller, childNodes[current], sideOffset);
  if (scrollOffset) scroller.scrollTo(scrollOffset, 0);
}

export var TabBar = function TabBar(_ref) {
  var _ref$selected = _ref.selected,
      selected = _ref$selected === void 0 ? 0 : _ref$selected,
      items = _ref.items,
      onChange = _ref.onChange,
      _ref$sideOffset = _ref.sideOffset,
      sideOffset = _ref$sideOffset === void 0 ? 48 : _ref$sideOffset,
      _ref$tabProps = _ref.tabProps,
      tabProps = _ref$tabProps === void 0 ? {} : _ref$tabProps,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? "inherit" : _ref$color,
      _ref$borderColor = _ref.borderColor,
      borderColor = _ref$borderColor === void 0 ? "grey.100" : _ref$borderColor,
      _ref$activeColor = _ref.activeColor,
      activeColor = _ref$activeColor === void 0 ? "primary" : _ref$activeColor,
      _ref$activeTextColor = _ref.activeTextColor,
      activeTextColor = _ref$activeTextColor === void 0 ? "inherit" : _ref$activeTextColor,
      _ref$height = _ref.height,
      height = _ref$height === void 0 ? "2.5rem" : _ref$height,
      _ref$scrollBehavior = _ref.scrollBehavior,
      scrollBehavior = _ref$scrollBehavior === void 0 ? "smooth" : _ref$scrollBehavior,
      restProps = _objectWithoutPropertiesLoose(_ref, ["selected", "items", "onChange", "sideOffset", "tabProps", "color", "borderColor", "activeColor", "activeTextColor", "height", "scrollBehavior"]);

  var scroller = useRef(null);

  var _useState = useState(selected),
      current = _useState[0],
      setCurrent = _useState[1];

  var handleClick = function handleClick(tabItem) {
    return function (_ref2) {
      var target = _ref2.target;
      target.blur();

      if (onChange) {
        onChange(tabItem);
        return;
      }

      var scrollOffset = getScrollOffset(scroller.current, target, sideOffset);
      if (scrollOffset) scroller.current.scrollTo(scrollOffset, 0);
      setCurrent(tabItem.key);
    };
  };

  useEffect(function () {
    setInitialScroll(scroller.current, selected, sideOffset);
  }, []);
  useEffect(function () {
    setInitialScroll(scroller.current, selected, sideOffset);
    setCurrent(selected);
  }, [selected]);
  return React.createElement("div", null, React.createElement(Wrapper, Object.assign({
    borderColor: borderColor
  }, restProps), React.createElement(Slider, {
    ref: scroller,
    scrollBehavior: scrollBehavior
  }, items && items.map(function (tabItem) {
    var restProps = tabItem.tabProps || tabProps;
    return React.createElement(Tab, Object.assign({
      key: "tab-" + tabItem.key,
      onClick: handleClick(tabItem),
      active: tabItem.key === current,
      borderColor: tabItem.key === current ? activeColor : "transparent",
      color: tabItem.key === current ? activeTextColor : color,
      height: height
    }, restProps), tabItem.render ? tabItem.render(tabItem, current) : tabItem.label);
  }))));
};